<template>
    <div>
        <header-portrate />
    <div class="m-2">
        <bread-crumbs :items="items" />
        
        <tabs-comp :tabs="tabs" />

        <v-row :style="`direction:`+lang.dir">
             <v-col
            cols="12"
            md="5"
            sm="12">
            <b-input-group class="mt-3">
                <b-form-input placeholder="Start Date" style="background:#eee" type="date" v-model="sdate"></b-form-input>
                <b-form-input placeholder="End Date" style="background:#eee" type="date" v-model="edate"></b-form-input>
                <b-input-group-append style="border-left:1px solid #CCC">
                    <b-button variant="light" style="background: darkblue !important;font-size:14px;color:#FFF;padding:7px 22px;border-radius:5px" @click="getReport()">{{lang.search}}</b-button>
                </b-input-group-append>
            </b-input-group>
            </v-col>
            <v-col cols="12" md="7" sm="12" class="text-right">
                <b-button variant="light" style="width:145px;margin-right:9px;" class="mytab mytab1 mt-3" @click="exportE('e')">{{lang.export_excel}}</b-button>
                <b-button variant="light" style="width:145px;margin-right:3px;display:none;background:red;color:#FFF" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
            </v-col>
        </v-row>

        <template>
        <v-data-table
            :headers="headers"
            :items="cardrows"
            :items-per-page="10"
            class="elevation-1"
        ></v-data-table>
        </template>


    </div>
    <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default{
    components:{BreadCrumbs, TabsComp, HeaderPortrate, Footer},
    data() {
        return {
            
            cardrows:[
                
            ],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            modal1: false,
            active_tab:2,
            sdate:'',
            edate: '',
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function() {
            return [
                {text: this.lang.invoiceno , value:'invoice_number'},
                {text: this.lang.customer_name , value:'full_name'},
                {text: this.lang.invoice_date , value:'inv_date'},
                {text: this.lang.total , value:'total'},
                {text: this.lang.vat , value:'vat'},
                {text: this.lang.ftotal , value:'ftotal'},
            ]
        },
        items: function() {
            return {
                text: this.lang.sales_report,
                    disabled: true,
                    to: '/reports/sales-reports',
                }
        },
        tabs: function() {
            let tabs =  [
                {
                    index:3,name:this.lang.vat_report,href:'/reports/vat-reports',class:'mytab4',smclass:'font-small'
                },
                
            ];
            tabs.push(
                {
                    index:4,name:this.lang.income_report,href:'/reports/income-report',class:'mytab3',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:7,name:this.lang.account_statement,href:'/reports/statment-report',class:'mytab3',smclass:'font-small'
                }
            );
            
            tabs.push(
                {
                    index:8,name:this.lang.account_balance_report,href:'/reports/balance-report',class:'mytab3',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:8,name:this.lang.bnl_report,href:'/reports/bnl-report',class:'mytab3',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:8,name:this.lang.balance_report,href:'/reports/acbalance-report',class:'mytab3',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:2,name:this.lang.sales_report,href:'/reports/sales-reports',class:'mytab',smclass:'font-small'
                }
            );
            tabs.push({
                    index:5,name:this.lang.return_report,href:'/reports/return-reports',class:'mytab6',smclass:'font-small'
                }
            );
            tabs.push({
                    index:0,name:this.lang.received_voucher,href:'/finance/inreceipt',class: 'mytab4',smclass:'font-small'
                }
            );
            tabs.push({
                    index:1,name:this.lang.exchange_voucher,href:'/finance/outreceipt',class: 'mytab5',smclass:'font-small'
                }
            );
            return tabs;
        }
    },
    created() {
        this.getInvoices();
    },
    methods: {
        exportE(type){
            let url = '';
            if(type == 'e'){
                url = this.$store.state.SAMCOTEC.e_path;
            }else{
                url = this.$store.state.SAMCOTEC.pd_path;
            }
            const myhead = this.headers;
            const mydata = this.cardrows;
            const reportname = 'تقرير المبيعات';
            const period = this.sdate + ' - ' + this.edate;
            const head = []
            const keys = []
            
            if(myhead.length > 0){
                for(let i = 0;i < myhead.length;i++){
                    head.push(myhead[i].text);
                    keys.push(myhead[i].value);
                }
            }
            
            const post = new FormData();
            post.append("export" , 'inrecepit');
            post.append("keys",keys);
            post.append("header",head);
            if(mydata.length > 0){
                for(let i = 0;i< mydata.length;i++){
                    for(const [key, value] of Object.entries(mydata[i])){
                       post.append('data['+i+']['+key+']',value);
                    }
                }
            }
            post.append("reportname",reportname);
            post.append("period",period);
            axios.post( url , post)
            .then((res) => {
                window.open('../api/'+res.data.url,'_blank');
            })
        },
        getInvoices(){
            const post = new FormData();
            post.append("type" , 'allInvoices');
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[sdate]",this.sdate);
            post.append("data[edate]",this.edate);
            post.append('data[type]',0);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data.results.data;
                this.cardrows = res;
            })
        }
    },
}
</script>